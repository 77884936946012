
let importFiles = {};
config.style.scss.forEach((key) => {
    importFiles[key] = false;
});

let context;
// require scss from the overrides folder

// TODO: not tested
//context = require.context('@/overrides/client/assets/scss', true, /\.scss/);
context = require.context('@/', true, /\/overrides\/client\/assets\/scss\/.*\.scss/);

context.keys().forEach(key => {
    let cleanKey = key.split('/').pop().replace('.scss', '');

    if (importFiles.hasOwnProperty(cleanKey)) {
        module = context(key);
        importFiles[cleanKey] = true;
    }
});


// require remaining scss from the client folder
context = require.context('@/client/assets/scss', true, /\.scss/);
context.keys().forEach(key => {
    let cleanKey = key.replace('./', '').replace('.scss', '');

    // if this file is required by us, and was not yet imprted - get it now
    if (importFiles.hasOwnProperty(cleanKey) && importFiles[cleanKey] === false) {
        module = context(key);
        importFiles[cleanKey] = true;
    }
});





